//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  watch: {
    show() {
      if (this.show) this.$refs.dialog.show();
    },
  },
  methods: {
    on_option_select(option) {
      this.$emit("select", option);
    },
  },
};
