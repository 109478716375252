//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Movie from "@/components/movie/Movie";
import PopupMenu from "@/components/PopupMenu";
export default {
  components: {
    PopupMenu,
    Movie,
  },
  props: {
    emitSelection: {
      type: Boolean,
      default: false,
    },
    showMyRoles: {
      type: Boolean,
      default: true,
    },
    emptyTitle: {
      type: String,
      default: "Nothing to show here",
    },
    emptyDesc: {
      type: String,
      default: "No movies found!",
    },
    emptyIcon: {
      type: String,
      default: null,
    },
    emptyImage: {
      type: String,
      default: null,
    },
    movies: {
      type: Array,
      default() {
        return [];
      },
    },
    options: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      active_movie: null,
      show_menu: false,
    };
  },
  methods: {
    on_option_select(option) {
      this.$emit(option.emit, this.active_movie);
    },
    on_select(movie) {
      this.$emit("select", movie);
    },
    on_show_options(movie) {
      this.active_movie = movie;
      this.show_menu = true;
    },
  },
};
