import { render, staticRenderFns } from "./CurationList.vue?vue&type=template&id=a6d24746&scoped=true&"
import script from "./CurationList.vue?vue&type=script&lang=js&"
export * from "./CurationList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a6d24746",
  null
  
)

export default component.exports
import {QAvatar,QBtn,QDialog} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QAvatar,QBtn,QDialog})
