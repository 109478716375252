//
//
//
//
//
//
//

import BaseLayout from "@/layouts/Base";
import CurationList from "@/components/CurationList";
export default {
  name: "list-detail-page",
  components: {
    BaseLayout,
    CurationList,
  },
  computed: {
    list_id() {
      return parseInt(this.$route.params.id);
    },
  },
  metaInfo: {
    title: "Curation",
  },
};
